function loadJs(src,id) {
    return new Promise((resolve, reject) => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = src;
      script.id=id
      document.head.appendChild(script);
      script.onload = () => {
        resolve(script);
      };
      script.onerror = () => {
        reject(script);
      };
    });
  }
  
  export default loadJs;